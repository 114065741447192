import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";

const GalleryPage = ({ club }) => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Galerie";

    const fetchGalleries = async () => {
      if (!club?.id) return; // Ensure the club data is available

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/galeries?populate=*&filters[club][id][$eq]=${club.id}`
        );
        setGalleries(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching galleries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleries();
  }, [club]);

  if (loading)
    return <div className="text-center my-10 text-lg">Loading galleries...</div>;

  if (!club)
    return <div className="text-center my-10 text-lg">No club selected.</div>;

  if (galleries.length === 0)
    return (
      <div className="text-center my-10 text-lg">
        No galleries available for {club.Name}.
      </div>
    );

  return (
    <div>
      <SectionHeader title="Galerie" />
      <div className="container mx-auto my-10 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {galleries.map((gallery) => {
            const { documentId, Title, Photos } = gallery;
            const thumbnail = Photos?.[0]?.formats?.thumbnail?.url || Photos?.[0]?.url;

            return (
              <Link
                to={`/galeries/${documentId}`}
                key={documentId}
                className="block border rounded-lg overflow-hidden shadow hover:shadow-lg"
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}${thumbnail}`}
                  alt={Title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-800">{Title}</h3>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
