import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import PlayerCard from "../components/PlayerCard";
import StaffCard from "../components/StaffCard";
import getTeamLogo from "../utils/getTeamLogo";
import rankingData from "../data/fcchalais_ranking.json";
import gamesData from "../data/fcchalais_calendar_2025.json";
import Slider from "react-slick";

const TeamDetailsPage = ({ club }) => {
  const location = useLocation();
  const { team } = location.state || {};
  const documentId = team?.documentId;
  const teamId = team?.teamId;

  const [players, setPlayers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchRelatedData = async () => {
      if (!documentId) {
        console.error("Document ID is missing.");
        return;
      }

      try {
        const playersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/players?filters[team][documentId][$eq]=${documentId}&populate=*`
        );
        const fetchedPlayers = playersResponse.data.data || [];
        fetchedPlayers.sort((a, b) =>
          a.Name.localeCompare(b.Name, "fr", { sensitivity: "base" })
        );
        setPlayers(fetchedPlayers);

        const trainersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trainers?filters[team][documentId][$eq]=${documentId}&populate=*`
        );
        const fetchedTrainers = trainersResponse.data.data || [];
        fetchedTrainers.sort((a, b) => {
          if (a.Function === "Entraîneur" && b.Function !== "Entraîneur") {
            return -1;
          }
          if (a.Function !== "Entraîneur" && b.Function === "Entraîneur") {
            return 1;
          }
          return a.Name.localeCompare(b.Name, "fr", { sensitivity: "base" });
        });
        setTrainers(fetchedTrainers);
      } catch (error) {
        console.error("Error fetching related data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedData();
  }, [documentId]);

  if (loading) return <div className="text-center my-5">Loading...</div>;

  const { Name, Description, Photo } = team || {};
  const teamPhotoUrl = Photo
    ? `${process.env.REACT_APP_API_URL}${
        Photo.formats?.medium?.url || Photo.url
      }`
    : null;

  const sortedGames = gamesData.sort(
    (a, b) => new Date(a.matchDate) - new Date(b.matchDate)
  );
  const lastGameIndex = sortedGames.findIndex(
    (game) => new Date(game.matchDate) <= new Date()
  );

  const renderGameCard = (game) => (
    <div key={game.matchId} className="p-4">
      <div className="border rounded-lg shadow-lg overflow-hidden">
        <div className="bg-primary text-white p-6 grid grid-cols-3 items-center text-center">
          <div>
            <img
              src={getTeamLogo(game.teamNameA)}
              alt={game.teamNameA || "Team A"}
              className="w-16 h-16 mx-auto mb-2"
              onError={(e) => (e.target.src = "/team-logos/default.png")}
            />
            <p className="text-lg font-bold">{game.teamNameA || "Unknown Team"}</p>
          </div>
          <div>
            <h2 className="text-4xl font-extrabold">
              {game.matchState === 2
                ? `${game.scoreTeamA} - ${game.scoreTeamB}`
                : "VS"}
            </h2>
            <p className="text-sm">
              {new Date(game.matchDate).toLocaleString("fr-FR")}
            </p>
          </div>
          <div>
            <img
              src={getTeamLogo(game.teamNameB)}
              alt={game.teamNameB || "Team B"}
              className="w-16 h-16 mx-auto mb-2"
              onError={(e) => (e.target.src = "/team-logos/default.png")}
            />
            <p className="text-lg font-bold">{game.teamNameB || "Unknown Team"}</p>
          </div>
        </div>
        <div className="bg-white p-4">
          <p className="text-sm font-bold">{game.matchTypeName}</p>
          <p className="text-sm text-gray-600">{game.stadiumPlaygroundName}</p>
        </div>
      </div>
    </div>
  );

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    initialSlide: lastGameIndex >= 0 ? lastGameIndex : 0,
  };

  const currentRanking = rankingData.filter(
    (entry) => entry.leagueId === 13029 && entry.groupId === 1
  );

  return (
    <div>
      {teamPhotoUrl && (
        <div className="relative w-full max-h-[80vh] overflow-hidden bg-gradient-to-r from-primary to-dark">
          <img
            src={teamPhotoUrl}
            alt={`${Name} team`}
            className="w-full h-full object-cover object-top"
            style={{ maxHeight: "80vh" }}
          />
        </div>
      )}
      <SectionHeader title={`${Name}`} />
      <section className="my-10">
        <div className="container mx-auto">
          <div>
            <p className="mb-6 px-6 md:px-0" style={{ textAlign: "justify" }}>
              {Description}
            </p>
          </div>
          <h2 className="text-4xl font-bold mb-4 mt-10 text-center md:text-left">Tous les Matchs</h2>
          <Slider {...carouselSettings} ref={sliderRef}>
            {sortedGames.map(renderGameCard)}
          </Slider>

          {/* Ranking */}
          <h2 className="text-4xl font-bold mb-4 mt-10 mb-5 text-center md:text-left">
            Classement
          </h2>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border border-gray-300 text-left">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2">N°</th>
                  <th className="px-4 py-2">Équipe</th>
                  <th className="px-4 py-2">G</th>
                  <th className="px-4 py-2">N</th>
                  <th className="px-4 py-2">P</th>
                  <th className="px-4 py-2 hidden sm:table-cell">BP</th>
                  <th className="px-4 py-2 hidden sm:table-cell">BC</th>
                  <th className="px-4 py-2">Pts</th>
                </tr>
              </thead>
              <tbody>
                {currentRanking.map((entry) => {
                  const logoUrl =
                    getTeamLogo(entry.teamName) || "/team-logos/default.png";
                  return (
                    <tr
                      key={entry.teamId}
                      className={`${
                        entry.teamId === teamId
                          ? "bg-primary text-white font-bold"
                          : ""
                      }`}
                    >
                      <td className="px-4 py-2">{entry.position}</td>
                      <td className="px-4 py-2 flex items-center gap-3">
                        <img
                          src={logoUrl}
                          alt={`${entry.teamName} logo`}
                          className="w-6 h-6"
                          onError={(e) =>
                            (e.target.src = "/team-logos/default.png")
                          }
                        />
                        <span className="hidden md:inline">{entry.teamName}</span>
                      </td>
                      <td className="px-4 py-2">{entry.wins}</td>
                      <td className="px-4 py-2">{entry.draws}</td>
                      <td className="px-4 py-2">{entry.losses}</td>
                      <td className="px-4 py-2 hidden sm:table-cell">
                        {entry.goalsFor}
                      </td>
                      <td className="px-4 py-2 hidden sm:table-cell">
                        {entry.goalsAgainst}
                      </td>
                      <td className="px-4 py-2">{entry.points}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Staff Section */}
          <h2 className="text-4xl font-bold mb-4 mt-10 mb-5 text-center md:text-left">Staff</h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
            {trainers.map((trainer) => (
              <StaffCard key={trainer.id} staff={trainer} />
            ))}
          </div>

          {/* Players Section */}
          <h2 className="text-4xl font-bold mb-4 mt-10 mb-5 text-center md:text-left">Joueurs</h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
            {players.map((player) => (
              <PlayerCard key={player.id} player={player} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeamDetailsPage;
