import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const GalleryDetailsPage = () => {
  const { id } = useParams(); // Get the gallery's documentId from the URL
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/galeries/${id}?populate=*`
        );
        const data = response.data?.data; // Extract the gallery data directly
        setGallery(data || null);
      } catch (error) {
        console.error("Error fetching gallery:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGallery();
  }, [id]);

  if (loading)
    return <div className="text-center my-10 text-lg">Loading gallery...</div>;

  if (!gallery)
    return <div className="text-center my-10 text-lg">Gallery not found.</div>;

  const { Title, Photos } = gallery; // Directly access Title and Photos

  return (
    <div>
      <SectionHeader title={Title} />
      <div className="container mx-auto my-10 px-4">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {Photos?.map((photo) => (
            <div
              key={photo.id}
              className="border rounded-lg overflow-hidden shadow"
            >
              <img
                src={`${process.env.REACT_APP_API_URL}${photo.url}`}
                alt={photo.alternativeText || photo.name}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryDetailsPage;
