import React, { useState, useEffect } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const SponsorsPage = ({ club }) => {
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Sponsors - ${club.Name}`;

    const fetchSponsors = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/sponsors?populate=*&filters[club][id][$eq]=${club.id}`
        );
        setSponsors(response.data.data || []);
      } catch (error) {
        console.error("Error fetching sponsors:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSponsors();
  }, [club]);

  if (loading) return <div className="text-center my-5">Loading sponsors...</div>;

  if (!sponsors.length) {
    return <div className="text-center my-5">No sponsors found for {club?.Name}.</div>;
  }

  return (
    <div>
      <SectionHeader title={`Nos Sponsors`} />
      <div className="container mx-auto px-4 py-10">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-6 gap-8">
          {sponsors.map((sponsor) => {
            const { id, Name, Link, Logo } = sponsor;
            const logoUrl = Logo?.formats?.thumbnail?.url
              ? `${process.env.REACT_APP_API_URL}${Logo.formats.thumbnail.url}`
              : "";

            return (
              <div key={id} className="bg-white border rounded-lg overflow-hidden flex flex-col items-center p-4">
                <a href={Link} target="_blank" rel="noopener noreferrer">
                  {logoUrl ? (
                    <img
                      src={logoUrl}
                      alt={`${Name} logo`}
                      className="w-full h-40 object-contain"
                    />
                  ) : (
                    <div className="w-full h-40 bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-500">No logo</span>
                    </div>
                  )}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SponsorsPage;
