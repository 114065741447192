import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const TrainersPage = ({ club }) => {
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Entraîneurs - ${club?.Name || "Club"}`;

    const fetchTrainers = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trainers?populate=team&filters[club][id][$eq]=${club.id}`
        );
        setTrainers(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching trainers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrainers();
  }, [club]);

  if (loading)
    return <div className="text-center my-10 text-lg">Loading trainers...</div>;

  if (trainers.length === 0)
    return (
      <div className="text-center my-10 text-lg">
        No trainers found for {club?.Name}.
      </div>
    );

  return (
    <div>
      <SectionHeader title="Entraîneurs" />
      <div className="container mx-auto my-10 px-4">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Nom & Prénom
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Téléphone
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Fonction
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Équipe
                </th>
              </tr>
            </thead>
            <tbody>
              {trainers.map((trainer) => {
                const { id, Name, Surname, Email, Phone, Function } = trainer;
                const teamName = trainer.team?.Name || "N/A";

                return (
                  <tr
                    key={id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {`${Name} ${Surname}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <a
                        href={`mailto:${Email}`}
                        className="text-primary hover:underline"
                      >
                        {Email || "---"}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {Phone || "---"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {Function || "---"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {teamName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TrainersPage;
 