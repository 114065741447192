import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const PlayersPage = ({ club }) => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Tous les Joueurs";

    const fetchPlayers = async () => {
      if (!club?.id) return;

      let allPlayers = [];
      let currentPage = 1;
      let totalPages = 1;

      try {
        do {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/players?populate=team&filters[club][id][$eq]=${club.id}&pagination[page]=${currentPage}&pagination[pageSize]=50`
          );

          const { data, meta } = response.data;
          allPlayers = [...allPlayers, ...data];
          currentPage += 1;
          totalPages = meta?.pagination?.pageCount || 1;
        } while (currentPage <= totalPages);

        setPlayers(allPlayers);
      } catch (error) {
        console.error("Error fetching players:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, [club]);

  if (loading)
    return <div className="text-center my-10 text-lg">Loading players...</div>;

  if (!club)
    return <div className="text-center my-10 text-lg">No club selected.</div>;

  if (players.length === 0)
    return (
      <div className="text-center my-10 text-lg">
        No players found for {club.Name}.
      </div>
    );

  return (
    <div>
      <SectionHeader title="Tous les Joueurs" />
      <div className="container mx-auto my-10 px-4">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Nom & Prénom
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Équipe
                </th>
              </tr>
            </thead>
            <tbody>
              {players.map((player) => {
                const { id, Name, Surname, team } = player;
                const teamName = team?.Name || "N/A";

                return (
                  <tr
                    key={id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {`${Name} ${Surname}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {teamName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PlayersPage;
